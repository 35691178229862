

import React from "react";
import { GamePageData } from "./RecipeRescuePageData";

export const superGemHeroesPageData: GamePageData = {
    metaPageTitle: "Super Gem Heroes Adventure Match-3 Game - iDream Interactive",
    metaKeywords: "super gem heroes, match 3 games, the match 3, free match 3 games",
    metaDescription: "Help Gem Boy defeat the Boogeyman! Learn more about our very first match-3 game which was available on Facebook.",
    pageTitle: "Super Gem Heroes",
    descriptionTitle: "Help Gem Boy Defeat the Boogeyman!",
    description: <>
        <p>Super Gem Heroes is a fun match-3 puzzle game for all ages. Can you help Gem Boy defeat the Boogeyman and save Dreamland?</p>
        <p>
            Mix and combine Gems to unleash Gem Boy’s super powers! Play with friends to compete for the highest score.
            Let’s defeat the Boogeyman and be a Super Gem Hero!
        </p>
    </>,
    links: [
    ]
}
